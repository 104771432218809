<template>
  <div elevation="0" class="mt-4 mb-8">
    <div>
      <h3 class="primary--text mb-2">{{ blockTitle }}</h3>
      <span v-if="hint">(審核通過後隨時都可以再加入)</span>
    </div>

    <v-divider></v-divider>

    <template v-if="readonly">
      <liffMemberItemTemplate>
        <template v-slot:avatar>
          <memberAvatar lock />
        </template>
        <template v-slot:content>
          <p class="primary--text ml-2 mb-0">審核通過後隨時都可以邀請人</p>
        </template>
      </liffMemberItemTemplate>
    </template>

    <template v-else>
      <template v-for="(item, index) in membersObj">
        <member-list
          :key="index"
          :memberRole="index"
          :members="item"
          :control="control"
          :providerId="providerId"
          :storeId="storeId"
          :deleteItemCallback="deleteItemCallback"
          :role="role"
          :memberRoles="memberRoles"
          :refresh="refresh"
        />
      </template>
      <!-- <member-list main :members="masters" :control="control" :providerId="providerId" :storeId="storeId"
        :deleteItemCallback="deleteItemCallback" :role="role" />
      <member-list :members="otherMembers" :control="control" :providerId="providerId" :storeId="storeId"
        :deleteItemCallback="deleteItemCallback" :role="role" /> -->
    </template>

    <v-row v-if="readonly || showAction">
      <v-col cols="8">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              v-if="showInviteBtn"
              :disabled="readonly"
              width="100%"
              color="primary"
              >{{ $t("action.send_invite") }}</v-btn
            >
          </template>
          <v-list>
            <template v-for="(item, index) in ['co_manager']">
              <v-list-item
                v-if="item != 'main_manager'"
                :key="index"
                @click="send(item)"
              >
                <v-list-item-title>{{ $t(item) }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-btn
          depressed
          v-if="showRefreshBtn"
          :disabled="readonly"
          width="100%"
          color="primary"
          outlined
          @click="refresh"
          >{{ $t("action.refresh") }}</v-btn
        >
      </v-col>
    </v-row>

    {{ liffError }}
  </div>
</template>

<script>
export default {
  components: {
    liffMemberItemTemplate: () =>
      import("@/modules/member/components/liffMemberItemTemplate.vue"),
    memberList: () => import("@/modules/member/components/liffMemberList.vue"),
    memberAvatar: () => import("@/modules/member/components/memberAvatar.vue"),
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    autoInvite: {
      type: Boolean,
      default: false,
    },
    // 是否能邀請/刪除會員
    control: {
      type: Boolean,
      default: false,
    },
    isMainManager: {
      type: Boolean,
      default: false,
    },
    shareConfig: {
      type: Array,
      default: () => [],
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "provider",
      validator: (value) => {
        return ["store", "provider"].includes(value);
      },
    },
    hint: {
      type: Boolean,
      default: false,
    },
    providerId: String,
    storeId: String,
    otpApi: {
      type: Function,
    },
  },
  data: () => ({
    liffError: null,
    memberTypes: {
      store: ["signer"],
      provider: ["picker", "dispatcher", "freightman"],
    },
  }),
  computed: {
    memberRoles() {
      let arr = ["main_manager", "co_manager"];
      arr = arr.concat(this.memberTypes[this.role]);
      return arr;
    },
    membersObj() {
      const vm = this;
      const obj = {};
      let curr;
      for (const i in this.memberRoles) {
        curr = this.memberRoles[i];
        let data = [];
        switch (curr) {
          case "main_manager":
            data = vm.members.filter((member) => member.isMaster);
            break;
          case "co_manager":
            data = vm.members.filter(
              (member) =>
                !member.isMaster &&
                Object.prototype.hasOwnProperty.call(member, "roles") &&
                (!member.roles || member.roles.length == 0)
            );
            break;
          default:
            data = vm.members.filter(
              (member) =>
                Object.prototype.hasOwnProperty.call(member, "roles") &&
                member.roles &&
                member.roles.includes(curr)
            );
            break;
        }
        obj[curr] = data;
      }

      return obj;
    },
    masters() {
      return this.members.filter((member) => member.isMaster);
    },
    otherMembers() {
      return this.members.filter((member) => !member.isMaster);
    },
    showInviteBtn() {
      if (this.readonly) return true;
      if (!this.control) return false;
      if (!this.isMainManager) return false;
      return true;
    },
    showRefreshBtn() {
      return true;
    },
    tokenRole() {
      return this.$store.getters["token/tokenRole"];
    },
    blockTitle() {
      if (this.role == "provider") return this.$t("provider.co-maintainer");
      if (this.role == "store") return this.$t("store.co-maintainer");
      return "";
    },
  },
  mounted() {
    if (this.autoInvite) {
      this.send();
    }
  },
  methods: {
    deleteItemCallback(memberItem) {
      const index = this.members.findIndex(
        (member) => member.id == memberItem.id
      );
      if (index < 0) return;
      this.members.splice(index, 1);
    },
    refresh() {
      this.$emit("refresh");
    },
    async send(role) {
      if (!this.isMainManager) return;

      if (window.liff.isLoggedIn() === false) {
        this.$snotify.error(null, "此功能僅限LIFF模式下使用");
        return;
      }

      this.$store.dispatch("loading/active");
      try {
        role = role != "co_manager" ? role : null;
        await this.$emit("setInviteRole", role);

        const otpCode = await this.otpApi();
        this.$emit("setOtpCode", otpCode);
        await this.$nextTick();

        window.liff.shareTargetPicker(this.shareConfig);
      } catch (error) {
        console.warn(error);
        this.$snotify.error(null, "此功能僅限LIFF模式下使用");
        this.liffError = error;
      } finally {
        this.$emit("setInviteRole", "");
        this.$emit("setOtpCode", "");

        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>
